import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Customer } from '../../members/member.model';
import { Utils } from '../../../shared/utils/Utils';
import { Client } from '../../clients/client.model';
import { FulfillmentBoard, FulfillmentCustomer, Request, StatusChange } from '../../requests/request.model';
import { Board } from '../../boards/board.model';
import { SentPrompt } from '../../prompts/sent-prompt.model';
import { Prompt } from '../../prompts/prompt.model';
import { TrayService } from '../../../shared/trays/tray.service';
// import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
// import { PromptService } from 'app/+client-admin/prompts/prompt.service';
import cuid from 'cuid';
// import { FulfillmentMemberTrayComponent } from 'app/shared/trays/fulfillment-member-tray/fulfillment-member-tray.component';
import _ from 'lodash';
import { InfiniteSelectConfig } from 'app/shared/infinite-select/infinite-select.component';
import { RequestUsersGqlService } from '../requests-gql/request-users-list-gql.service';
import { UserListManager } from 'app/+client-admin/users/user-list-manager';
import { DeleteRequestMemberMappingGqlService } from '../requests-gql/delete-request-member-mapping-gql.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'app/+client-admin/users/user.model';

@Component({
  selector: 's5-request-fulfill-form',
  templateUrl: './request-fulfill-form.component.html',
  styleUrls: ['./request-fulfill-form.component.scss']
})
export class RequestFulfillFormComponent implements OnInit {

  constructor( 
    public trayService: TrayService,
    protected requestUsersGqlService: RequestUsersGqlService,
    private deleteRequestMemberMappingGqlService: DeleteRequestMemberMappingGqlService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  @Input() request: Request;
  @Input() fulfillmentCustomers: FulfillmentCustomer[];
  @Input() fulfillmentBoards: FulfillmentBoard[];
  @Input() disabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() client: Client;
  @Input() user: User;
  @Input() requestStatuses: { value: string }[];
  @Input() permissions = Utils.initRolePermissions();

  _fulfillmentCustomerStatuses: any = [];
  get fulfilmentCustomerStatuses() {
    return this._fulfillmentCustomerStatuses
  };
  @Input('fulfillmentCustomerStatuses') set fulfillmentCustomerStatuses(value: any) {
    this._fulfillmentCustomerStatuses = value;
    this.fulfillmentCustomerStatusesforDropdown = value.filter(s => s.status !== 'Inactive');
  }
  
  @Output() sendPromptMembers: EventEmitter<any> = new EventEmitter<any>();

  fulfillmentCustomerStatusesforDropdown: any = [];

  public fulfillmentMembers$: BehaviorSubject<FulfillmentCustomer[]> = new BehaviorSubject([]);

  // Default tray controls
  visible = false;
  innerVisible = false;
  profile = false;
  tray: any;
  embed: boolean = false;
  sfOpportunityID: string;

  memberTray: any = {
    id: 'fulfillmentMember',
    trayTitle: 'Select Customers to Request',
    trayTitleTooltip: '',
    innerTrayTitle: 'Member Filter',
    trayWidth: 600,
    trayWidthInner: 500,
    data: {}
  };

  addMemberTray: any = {
    id: 'addMember',
    trayTitle: 'Add a Member',
    trayTitleTooltip: '',
    innerTrayTitle: '',
    trayWidth: 600,
    trayWidthInner: 500,
    data: {}
  };

  boardTray: any = {
    id: 'fulfillmentBoard',
    trayTitle: 'Select Fulfillment Boards',
    trayTitleTooltip: '',
    innerTrayTitle: 'Board Filter',
    trayWidth: 500,
    trayWidthInner: 500,
    data: {}
  };

  promptsTray: any = {
    id: 'prompt',
    trayTitle: 'Prompts',
    trayTitleTooltip: '',
    innerTrayTitle: 'Member Prompts',
    trayWidth: 500,
    trayWidthInner: 500,
    data: {}
  };

  sentPromptsTray: any = {
    id: 'sentPrompt',
    trayTitle: 'Sent Prompts and Responses',
    trayTitleTooltip: '',
    innerTrayTitle: 'Story Detail',
    trayWidth: 500,
    trayWidthInner: 500,
    data: {}
  }

  trustedContactsTray: any = {
    id: 'trustedContact',
    trayTitle: 'Send Request to Trusted Contacts',
    trayTitleTooltip: '',
    innerTrayTitle: 'Trusted Contact Profiles',
    trayWidth: 500,
    trayWidthInner: 500,
    data: {}
  };

  notesTray: any = {
    id: 'note',
    trayTitle: 'Fulfillment Notes',
    trayTitleTooltip: '',
    innerTrayTitle: '',
    innerTrayProfileTitle: '',
    trayWidth: 500,
    trayWidthInner: 500,
  };

  activityFilters = {};
  activityTypes = [];
  activityTypesOnRequest = [];
  memberFilterTrigger = false;
  boardFilterTrigger = false
  members: Customer[];
  filteredMembers: Customer[];
  fulfillmentCustomerIds: string[];
  boards: Board[];
  filteredBoards: Board[];
  selectedBoards: Board[];
  selectedBoardIds: string[];

  customerGroups: any[];
  sentPromptCounts: any;
  currSort = 'lname';
  currCompanySort = 'company.name';
  groupSortProp = 'name';
  isLoaded = false;
  showFilter = false;
  filterVal;
  boardFilterVal;
  memberCheckedFlag: boolean = false;
  selectAll: false;
  prompt: Prompt;
  prompts: Prompt[];
  currTrustedContactSort = 'trustedContact.name';
  currFilteredMemberSort = 'lname';
  currBoardSort = 'name';
  currFilteredBoardSort = 'name';
  trustedContactMemberList;
  userListManager: UserListManager;
  userList: User[] = [];

  bgBlank = '/assets/img/blank_avatar.jpg';
  collapseMembers = false;
  collapseBoards = false;
  collapseInfo = false;
  scrolling = false;

  fulfill: boolean = false;

  selectAllBoards: any;
  boardCheckedFlag: any;

  private assignedToConfig: InfiniteSelectConfig = {
    entityName: 'User',
    key: 'id',
    itemsPerPage: 25,
    offset: 0,
    bindLabel: 'name',
    bindValue: null,
    placeholder: 'Select a User',
    useFirst: false,
    appendTo: 'body',
    dropdownPosition: 'bottom',
    closeOnSelect: true,
    clearable: true,
    variables: {}
  }

  protected membersTableConfig: any = {
    label: 'Fulfillment Members Table'
  }

  protected boardsTableConfig: any = {
    label: 'Fulfillment Boards Table'
  }

  ngOnInit(): void {
    this.sfOpportunityID = this.route.snapshot.queryParamMap.get('OpportunityId');
    this.embed = !!this.sfOpportunityID || this.route.snapshot.data['isEmbed'];
    this.assignedToConfig.variables = { clientId: this.client.id };

    this.assignedToConfig.whereClause = {
      clientRoles: {
        ClientRole: {client_id: {_eq: this.client.id}},
        allowRequestAssignment: {_eq: true}
      }
    }
    
    this.activityTypes = this.client.activityTypes
    this.activityTypesOnRequest = this.activityTypes.filter(at => at.includeOnRequestForm);
    this.fulfill = this.request.requestStatus !== 'New' || this.client.newRequestFulfillmentEnabled;
    this.tray = this.memberTray;

    this.memberTray.data = {
      request: this.request,
      fulfillmentCustomers: this.fulfillmentCustomers,
      assignedToConfig: this.assignedToConfig,
      client: this.client,
      user: this.user
    }

    this.addMemberTray.data = {
      request: this.request,
      fulfillmentCustomers: [],
      client: this.client,
      user: this.user
    }

    this.boardTray.data = {
      request: this.request,
      fulfillmentBoards: this.fulfillmentBoards,
      client: this.client
    }

    this.promptsTray.data = {
      request: this.request,
      client: this.client,
      user: this.user,
      sendPromptMembers: this.fulfillmentCustomers.filter((RFC: FulfillmentCustomer) => RFC.customer && (!RFC.statusChange || RFC.statusChange.fulFillmentCustomerStatus !== 'Inactive'))
    };

    this.sentPromptsTray.data = {
      client: this.client,
      fulfillmentCustomers: this.fulfillmentCustomers,
    };

    this.trustedContactsTray.data = {
      client: this.client,
      user: this.user,
      members: this.members,
      trustedContactMemberList: this.fulfillmentCustomers.filter((RFC: FulfillmentCustomer) => RFC.customer && (!RFC.statusChange || RFC.statusChange.fulFillmentCustomerStatus !== 'Inactive')),
    }

    this.notesTray.data = {
      request: this.request,
      client: this.client,
      user: this.user
    }
    
    this.initFilters();
    this.isLoaded = true;

  }

  scroll(e) { 
    Utils.trackScroll('.table-window .ng-select-opened ', '.ng-dropdown-panel', '.table-window'); 
  }

  calculateEngagementScore(member:Customer) {
    return member.activityLogs.reduce((totalPoints, memberActivityLog)=> {
      return totalPoints += memberActivityLog.activityType.points;
    }, 0);
  }

  sentCount(sentPrompts: SentPrompt[]) {
    const count = sentPrompts && sentPrompts.filter((sp: SentPrompt) => sp.request && sp.request.id  === this.request.id).length || 0;
    return count;
  }

  respondedCount(sentPrompts: SentPrompt[]) {
    let count = 0;
    sentPrompts && sentPrompts.forEach((sp) => {
      if (sp.stories && sp.request && sp.request.id === this.request.id) {
        count += sp.stories.length;
      }
    });
    return count;
  }

  // Fulfillment Status Changes
  protected fulfillmentStatusChange(member: FulfillmentCustomer, e) {
    if (typeof member.statusChange?.statusChangeDate === 'object' && member.statusChange?.statusChangeDate?.toString().indexOf('1970') > -1) {
      delete member.statusChange.statusChangeDate;
    }

    if (member.statusChange.status === 'Completed' && !member.activityType && !this.request.activityType) {
      alert('This Request or this Fulfillment Member must have an activity type selected when a status is marked "Completed".');
      setTimeout(() => {
        delete member.statusChange.status;
        delete member.statusChange.fulFillmentCustomerStatus;
      });
      
    }
    if (member.statusChange.status === 'Inactive') {
      member.checked = false;
    } else {
      member.checked = true;
    }
    delete member.statusChange.id;
    this.updateFulfillmentMembers();
  }

  cleanupDate = Utils.cleanupDate;

  // Table Sorting ---------------------------------------------
  public sortMembers(serverSortChange: any) {}

  public sortBoards() {}

  // Filter Controls ---------------------------------------------
  initFilters() {
    this.resetFulfillmentMembers();
    this.resetBoards();
  }

  resetFulfillmentMembers() {
    if (this.fulfillmentCustomers) {
      this.fulfillmentCustomers.length = 0;
      this.request.fulfillmentCustomers
      .map((FC: FulfillmentCustomer) => {
        const member = JSON.parse(JSON.stringify(FC));
        member.memberId = member.customer?.id;
        member.statusChange = member.statusChanges && member.statusChanges[0] || {} as StatusChange;
        member.statusChange.status ||= this.embed ? 'Requested' : null;
        member.assignedToConfig = JSON.parse(JSON.stringify(this.assignedToConfig));
        member.assignedToConfig.value = member.assignedTo;
        member.checked = member.statusChange.fulFillmentCustomerStatus !== 'Inactive';
        if (!member.company && member.customer) {
          member.company = member.customer.company;
        }
        this.fulfillmentCustomers.push(member);
      });
      this.updateFulfillmentMembers();
    }
  }

  updateFulfillmentMembers() {
    this.fulfillmentCustomerIds = this.fulfillmentCustomers.map((FC: FulfillmentCustomer) => {
      if (!FC.company && FC.customer) {
        FC.company = FC.customer.company;
      }
      if (FC.customer) {
        // Utils.calculateMemberAWTD(FC.customer);
      }
      return FC.customer?.id
    });
  }

  resetBoards() {
    this.selectedBoards = JSON.parse(JSON.stringify(this.request.fulfillmentBoards));
  }

  showMemberTray(member?: FulfillmentCustomer, tab?: string ) {
    this.tray = this.memberTray;
    delete this.tray.data.createCompany;
    delete this.tray.data.createCustomer;
    delete this.tray.data.action;
    this.tray.data.tab = tab;
    this.tray.data.checkId = (member?.id || '') + member?.company?.id + tab;
    this.tray.data.filterMember = member;
    if (!tab) {
      this.tray.trayTitle = 'Select Customers to Request';
      delete this.tray.data.filterMember;
    }
    
    this.trayService.toggleTray(this.tray);

    setTimeout(() => {
      const filterManager = this.tray.data.advancedFilters;
      if (filterManager) {
        if (member && tab === 'Member') {
          const sectionField = filterManager.getField('Company Filters', 'name');

          if (sectionField) {
            sectionField.value = member.company?.name && [member.company.name];
          }
        }
      }
      this.tray.data?.filterCallback && this.tray.data.filterCallback();
    }, 20);
  }

  showAddMemberTray(member?: FulfillmentCustomer, tab?: string ) {
    this.tray = this.addMemberTray;
    delete this.tray.data.createCompany;
    delete this.tray.data.createCustomer;
    this.tray.data.action === 'create';
    this.tray.data.fulfillmentCustomers = this.fulfillmentCustomers;
    this.tray.data.checkId = member?.id;
    this.tray.data.addMember = member;
    
    this.trayService.toggleTray(this.tray);

    setTimeout(() => {
      const filterManager = this.tray.data.advancedFilters;
      if (filterManager) {
        const sectionField = filterManager.getField('Company Filters', 'name');

        if (sectionField) {
          sectionField.value = member.company?.name && [member.company.name];
        }
      }
      this.tray.data && this.tray.data.filterCallback && this.tray.data.filterCallback();
    }, 20);
  }

  showMemberCard(member) {
    if (member.__typename === 'Customer' && !this.permissions.member_edit) {
      console.log("User does not have permission to view members");
      return;
    }
    if (member.__typename === 'Company' && !this.permissions.company_edit) {
      console.log("User does not have permission to view companies");
      return;
    }

    this.tray = {
      id: 'memberCard',
      trayTitle: member.__typename === 'Customer' ? 'Member Profile' : 'Company Profile',
      trayTitleTooltip: '',
      trayWidth: 500,
      trayWidthInner: 500,
      data: {
        member,
        checkId: member.id
      }
    };

    this.trayService.toggleTray(this.tray);
  }

  showSendPromptsTray() {
    this.tray = this.promptsTray;
    this.tray.data.sendPromptMembers = _.uniqBy(this.fulfillmentCustomers.filter((FC: FulfillmentCustomer) => FC.customer && (!FC.statusChange.fulFillmentCustomerStatus || FC.statusChange.fulFillmentCustomerStatus !== 'Inactive')), (FC: FulfillmentCustomer) => FC.customer.id);
    this.tray.data.checkId = 'send prompts';
    this.trayService.toggleTray(this.tray);
  }

  // For sending request to selected trusted contacts
  showTrustedContactsTray() {
    this.tray = this.trustedContactsTray;
    this.tray.data.trustedContacts = [];

    this.tray.data.trustedContactEntities = 
      this.fulfillmentCustomers
        .filter((FC: FulfillmentCustomer) => (FC.customer?.trustedContacts.length || FC.company?.trustedContacts.length ) && (!FC.statusChange.fulFillmentCustomerStatus || FC.statusChange.fulFillmentCustomerStatus !== 'Inactive'))
        .map((FC: FulfillmentCustomer) => {
          const entity = FC.customer || FC.company;
          const trustedContacts = Utils.getTrustedContacts(FC);

          return {
            ...entity,
            // trustedContacts
          };
        });

    this.tray.data.checkId = 'trusted';
    this.trayService.toggleTray(this.tray);
  }

  showMemberPromptsTray(member: Customer) {
    this.tray = this.sentPromptsTray;
    this.tray.data.member = member;
    this.tray.data.request = this.request;
    this.tray.data.checkId = member?.id;
    this.trayService.toggleTray(this.tray);
  }

  // Show trusted contact details
  showTrustedContactTray(member: FulfillmentCustomer) {
    this.tray = this.trustedContactsTray;
    this.tray.data.member = member;
    this.tray.data.trustedContacts = Utils.getTrustedContacts(member);
    this.tray.data.innerCheckId = member.id;
    this.trayService.toggleInnerTray(this.tray);
  }

  getTrustedContacts(member: FulfillmentCustomer) {
    return Utils.getTrustedContacts(member);
  }

  removeMember(member: FulfillmentCustomer) {
    member.checked = false;
    member.statusChange.fulFillmentCustomerStatus = 'Inactive';
    member.statusChange.status = "Inactive"

    if (member.customer?.id || member.company?.id) {
      delete member.statusChange.id;
    } else if (!member.id) {
      // do nothing
    } else {
      // delete any member that has no company nor customer defined
      // requestId is a misnomer
      this.deleteRequestMemberMappingGqlService.mutate({requestId: member.id}, { awaitRefetchQueries: true }).subscribe();
    }
  }

  showBoardTab(board) {
    // There ought to be a better way to do this.
    const url = this.router.serializeUrl(
      this.router.createUrlTree([board.secureWithSSO ? 'sso' : 'b', board.id, Utils.getBoardUrlNamePart(board)])
    );
    window.open(url, '_blank');
  }

  removeBoard(FB: any) {
    FB.checked = false;
    FB.status = 'inactive';
  }

  protected addBlankMember() {
    const blankMember: FulfillmentCustomer = {
      id: cuid(),
      request_id: this.request.id,
      statusChange: {},
      statusChanges: [],
      assignedToConfig: JSON.parse(JSON.stringify(this.assignedToConfig))
    }
    this.fulfillmentCustomers.unshift(blankMember);
  }

  protected activityInput(event, member: FulfillmentCustomer) {
    member.activityType_id = event?.id;
    member.markedForChange = true;
  }

  protected assignToValueChange(user: any, member: FulfillmentCustomer) {
    if (user) {
      member.assignedTo = user;
      member.assignedTo_id = user.id;
    } else {
      delete member.assignedTo;
      delete member.assignedTo_id;
    }
    member.markedForChange = true;
  }

  protected needByDateChange(member: FulfillmentCustomer) {
    if (member.needByDate?.toString().indexOf('1970') > -1) {
      delete member.needByDate;
    }
    member.markedForChange = true;
  }

  protected takeNotes(member: FulfillmentCustomer) {
    this.tray = this.notesTray;
    this.tray.data.member = member;
    this.tray.data.checkId = member?.id;
    member.markedForChange = true;
    this.trayService.toggleTray(this.tray);
  }
  
  // This form of function keeps 'this' context here
  protected trackBy = (index: number, member: FulfillmentCustomer) =>  {
    return member.id;
  }

}
