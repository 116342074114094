// this is the prompt service which facilitates communications
//  with the server to send prompt invites

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'app/shared/utils/Utils';
import { forkJoin, Observable } from 'rxjs';
import { Request, RequestAssignedPayload } from './request.model';

import { FulFillRequestPayload, CreateRequestPayload } from './request.model';
import { fork } from 'child_process';

interface Msg {
  msg: string;
  date: Date;
}

@Injectable()
export class RequestService {

  constructor(private http: HttpClient) {}

  public sendRequestFulfillEmail(payload: FulFillRequestPayload): Observable<Msg> {
    const body = {
      hostname: payload.hostname,//
      request: {
        id: payload.request.id,//
        needDescription: payload.request.needDescription,//
        requesterName: payload.request.requesterName,//
        requesterEmail: payload.request.requesterEmail,//
        noteToRequester: payload.request.noteToRequester,//
      }
    }

    return this.http.post<Msg>('/api/email/fulfill-request', body);
  }

  public sendRequestCreatedEmail(request: Request): Observable<Msg> {
    const vars = {
      id: request.id,
      requesterName: request.requesterName,
      requesterEmail: request.requesterEmail,
      number: request.number,
      needDescription: request.needDescription,
      needFor: request.needFor,
      needByDate: request.needByDate,
    }
    const payload: CreateRequestPayload = {
      request: vars,
      hostname: Utils.fullHost()

    };
    return this.http.post<Msg>('/api/email/create-request', payload);
  }

  public sendRequestAssignedEmail(request: Request): Observable<Msg[]> {
    const emailRequests$: Observable<Msg>[] = [];
    const payload: RequestAssignedPayload = {
      request: {
        id: request.id,
        requestStatus: request.requestStatus, // not used
        number: request.number,
        needDescription: request.needDescription,
        needFor: request.needFor,
        needByDate: request.needByDate,
        requesterName: request.requesterName,
        teamId: request.teamId,
        assignedTo_users: request.assignedTo_users.filter(assignedTo_user => {
          const user = assignedTo_user.assignedTo;
          const flag = user.email && user.email !== request.requesterEmail && user.clientRoles?.find(clientRole => clientRole.ClientRole.client);
          delete user.clientRoles;
          return flag;
        }).map(assignedTo_user => {
          const user = assignedTo_user.assignedTo;
          return {assignedTo: { id: user.id, email: user.email}};
        })
      },
      hostname: Utils.fullHost(),
    };
    if (request.team?.name) {
      payload.request.team = { 
        id: request.team.id, // not used
        name: request.team.name, 
        client: null // not used
      };
    }

    if (payload.request.assignedTo_users?.length > 0) {
      emailRequests$.push(this.http.post<Msg>('/api/email/request-assigned', payload));
    }

    return forkJoin(emailRequests$);
  };

  public shareRequestEmail(payload: any): Observable<Msg> {
    return this.http.post<Msg>('/api/email/share-request-trusted-contact', payload);
  }

}
